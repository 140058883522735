@import 'src/assets/styles/variables';

.goal-card {
  margin-bottom: 20px;

  &--sub {
    position: relative;
    margin-left: 50px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 100%;
      top: 50%;
      height: 1px;
      width: 50px;
      background-color: $border;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__head-action {
    display: flex;
    align-items: center;
  }

  &__note-btn {
    margin-right: 5px;
  }

  &__edit-btn {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    .tag {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    font-weight: 900;
    margin-right: 10px;
  }

  &__body {
    display: flex;
    padding-top: 10px;
    justify-content: space-between;
  }

  &__status-btn {
    font-size: 14px !important;
    padding: 3px 5px !important;
  }

  &__status-icon {
    &--achieved {
      color: $success;
    }

    &--ongoing {
      color: $black;
    }

    &--notachieved {
      color: $errorRed;
    }

    &--paused {
      color: $danger;
    }
  }
  &__status-border {
    &--achieved {
      border-color: $success !important;
      border-width: 2px;
    }

    &--ongoing {
      border-color: $black !important;
      border-width: 2px;
    }

    &--notachieved {
      border-color: $errorRed !important;
      border-width: 2px;
    }

    &--paused {
      border-color: $danger !important;
      border-width: 2px;
    }
  }
  .tag {
    width: auto;
    padding: 4px 15px;
    border-color: $border;
    margin-right: 0 !important;
    background-color: $whiteSmoke;

    .icon {
      //margin-left: 10px;
    }
  }
}
