@import 'src/assets/styles/variables';

button.common-btn {
  &--active {
    color: $mainBlue;
    border-color: $mainBlue;
  }

  &.ant-btn-icon-only {
    .icon {
      display: block;
      margin: 0 auto;
    }
  }
}
