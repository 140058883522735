@import 'variables';

body {
  // font-family: 'Roboto', sans-serif;
  font-family: Montserrat, 'sans-serif';

  color: rgba(0, 0, 0, 0.85);
  overflow: hidden;
}

.page {
  width: 1200px;
  min-width: 1200px;
  height: 100%;
  margin: auto;
  justify-content: center;
  // width: 50%;
  padding: 8px;

  .common-card {
    width: 100%;
    // max-width: 1100px;
  }
}
.page-full {
  padding: 10px;
  // width:1024px;
  width: 100%;
  .common-card {
    width: 100%;
    // max-width: 1100px;
  }
}
.page-title {
  user-select: none;
}

.item-form {
  // display: flex;
  // padding: 0 0 40px;
  // justify-content: center;
  // background-color: transparent;

  .common-input,
  .autocomplete,
  .select-menu,
  .enroll-btn {
    margin-bottom: 20px;
  }
}

.items-search {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;

  .autocomplete {
    flex: 1;
    margin-bottom: 0;
  }

  .common-btn {
    margin-left: 30px;
  }
}

.common-form {
  .common-input,
  .radio-buttons,
  .select-menu,
  .checkbox,
  .textarea,
  .autocomplete {
    margin-bottom: 20px;
  }

  .common-form__row {
    display: flex;
    margin: 0 -15px;
  }

  .common-form__col {
    width: 50%;
    padding: 0 15px;
  }
}

.no-format {
  font-style: normal;

  em {
    font-style: italic;
  }
}

.ant-tabs-tab-active {
  border-color: $mainBlue !important;
}

.delete-window {
  .ant-modal-body {
    display: none;
  }

  &__footer {
    text-align: left;

    .common-btn {
      margin-right: 10px;
      margin-bottom: 8px;
      margin-left: 0;
    }
  }
}

.empty-btn {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.card-actions {
}

// TODO: Remove this style fix for header
.content.ant-layout-content {
  height: calc(100vh - 80px);
}
