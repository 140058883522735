@import 'src/assets/styles/variables';

.pn-form {
  &__dose24 {
    font-size: 14px;
    margin-top: -18px;
    border-bottom: 1px solid transparent;
    width: max-content;

    &--reached {
      border-bottom-color: $mainBlue;
    }
  }
}
